import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type LanguageSelectionProps = {
  languageCode: string;
};

const LanguageSelection: React.FC<LanguageSelectionProps> = ({ languageCode }) => {

  const data = useStaticQuery(graphql`
    query {
        images: allMarkdownRemark {
        edges {
          node {
            frontmatter {
              languageCode
              alt
              Image01 {
                childrenImageSharp {
                  gatsbyImageData(blurredOptions: {width: 43}, height: 29, width: 43, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {
        data.images.edges.filter(item => item.node.frontmatter.languageCode == languageCode).map((item) => (
          <GatsbyImage
            key={item.node.frontmatter.alt}
            image={item.node.frontmatter.Image01.childrenImageSharp[0].gatsbyImageData}
            alt={item.node.frontmatter.alt} />
        )
        )
      }
    </>
  );
}

export default LanguageSelection;