import React, { CSSProperties, useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../../style.css";
import LanguageSelection from "./Image";
import Logo from "../../assets/images/logo-transparent.svg";
import Icon1 from "../../assets/icons/icon_1.svg";
import Icon2 from "../../assets/icons/icon_2.svg";
import Icon3 from "../../assets/icons/icon_3.svg";
import Icon4 from "../../assets/icons/icon_4.svg";
import Icon5 from "../../assets/icons/icon_5.svg";
import Icon6 from "../../assets/icons/icon_6.svg";
import VenturiValve from "../../assets/products/Safetrans-4.svg";
import StandardValve from "../../assets/products/Safetrans-18.svg";
import en from "../../assets/documents/Safetrans_products_en2.pdf";
import nl from "../../assets/documents/Safetrans_products_nl2.pdf";
import nl_prod from "../../assets/documents/nl_get_to_know_us2.pdf";
import en_prod from "../../assets/documents/en_get_to_know_us2.pdf";

import { FormattedMessage, useIntl, changeLocale } from "gatsby-plugin-intl"

const imageStyle: CSSProperties = {
    margin: "auto"
}

const IndexContent: React.FC = () => {
    const [imgLocation, setImgLocation] = useState<string>("");
    const [languageCode, setLanguageCode] = useState<string>("en");

    const intl = useIntl();

    const changeLanguage = (code: string) => {
        if (code == "nl") {
            setLanguageCode("nl")
        } else if (code == "en") {
            setLanguageCode("en")
        }
        changeLocale(code);
    }

    const getPDFSource = (code: String) => {
        if (typeof window != "undefined" && window.location.pathname.includes("nl")) {
            return nl;
        } else {
            return en;
        }
    }

    const getPDFSourceKnowUs = (code: String) => {
        if (typeof window != "undefined" && window.location.pathname.includes("nl")) {
            return nl_prod;
        } else {
            return en_prod;
        }
    }

    return (
        <>
            <body data-spy="scroll" data-target="#myScrollspy" data-offset="0">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                    <div className="container-fluid max-width">
                        <a className="navbar-brand" href="#">
                            {/* <img alt="" width={250} height={70}> */}
                            <div className="logo">
                                <Logo />
                            </div>
                            {/* </img> */}
                            {/* <StaticImage src={logo} alt="" width={250} height={70}
                                className="d-inline-block align-text-top" /> */}
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-fill mobile-margin-nav mt-2 mx-auto w-100">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#products">
                                        <h5>
                                            <FormattedMessage id="menu_products" />
                                        </h5>
                                    </a>
                                    {/* <!-- data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" --> */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#applications">
                                        <h5>
                                            <FormattedMessage id="menu_applications" />
                                        </h5>
                                    </a>
                                    {/* <!-- data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" --> */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#about">
                                        <h5>
                                            <FormattedMessage id="menu_about" />
                                        </h5>
                                    </a>
                                    {/* <!-- data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" --> */}
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link active" href="#contact">
                                        <h5>
                                            <FormattedMessage id="menu_contact" />
                                        </h5>
                                    </a>
                                    {/* <!-- data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" --> */}
                                </li>


                                <li className="nav-item mobile-margin-navbar">
                                    <div className="dropdown">

                                        <button className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <LanguageSelection
                                                languageCode={intl.locale}
                                            />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a className="dropdown-item d-flex" href="#">
                                                    <StaticImage
                                                        src={`../../assets/images/NL.png`}
                                                        alt=""
                                                        // width={30}
                                                        height={24}
                                                        className="d-inline-block mx-auto align-text-top"
                                                        onClick={(e) => changeLanguage("nl")}
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item d-flex" href="#">
                                                    <StaticImage
                                                        src={`../../assets/images/UK.png`}
                                                        alt=""
                                                        // width={30}
                                                        height={24}
                                                        className="d-inline-block mx-auto align-text-top"
                                                        onClick={(e) => changeLanguage("en")}
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>


                        {/* <a className="navbar-brand" href="#">
                            <StaticImage
                                src="../../assets/images/UK.png"
                                alt=""
                                width={30}
                                height={24}
                                className="d-inline-block align-text-top"
                                onClick={(e) => changeLanguage("nl")}
                            />
                        </a> */}
                    </div>
                </nav>

                {/* <!-- BANNER --> */}
                <section title="banner">
                    <section className="container-fluid max-width pb-4">
                        <StaticImage src="../../assets/images/banner.jpg" className="img-fluid rounded mx-auto d-block" alt="..." />
                    </section>
                </section>


                <section title="product" className="container-fluid max-width py-4" id="products">
                    <section title="product-row-1" className="pt-4">
                        <div className="pb-5">
                            <h1 className="text-center title"><FormattedMessage id="first_title" /></h1>
                        </div>

                        <div className="mt-4 col-12 col-md-8 mx-auto">
                            <hr />
                        </div>

                        <div className="py-4">
                            <h5 className="text-center">
                                <FormattedMessage id="first_expl_p1" />

                                <br className="mb-2" />
                                <FormattedMessage id="first_expl_p2" />

                            </h5>
                        </div>
                        <div className="row no-gutters justify-content-md-center py-4">
                            <div className="col-10 col-md-4 product-card-wrapper-left rounded hover-shadow">
                                <div className="product-caption-left">
                                    <h3 className="product-caption-left-text">
                                        <FormattedMessage id="kraft" />
                                        <br />
                                        <FormattedMessage id="paper" />
                                    </h3>
                                </div>
                                <div className="product-img-left mx-auto">
                                    <StaticImage
                                        layout="fixed"
                                        height={400}
                                        style={imageStyle}
                                        src="../../assets/products/Safetrans-15.jpg"
                                        className="img-fluid d-block rounded zoom-img" alt="..." />
                                </div>
                            </div>
                            <div className="col-1 padding-only-web" />
                            <div className="col-10 col-md-4 product-card-wrapper-right rounded hover-shadow">
                                <div className="product-caption-right">
                                    <h3 className="product-caption-right-text">

                                        <FormattedMessage id="woven" />
                                        <br />
                                        <FormattedMessage id="poly" />

                                    </h3>
                                </div>
                                <div className="product-img-right">
                                    <StaticImage
                                        layout="fixed"
                                        height={400}
                                        style={imageStyle}
                                        className="img-fluid d-block rounded zoom-img" src="../../assets/products/Safetrans-3.jpg" alt="..." />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section title="product-row-2" className="pt-4">
                        <div className="py-4">
                            <h5 className="text-center">
                                <FormattedMessage id="we_offer" />
                            </h5>
                        </div>

                        <div className="row no-gutters justify-content-md-center pb-4">
                            <div className="col-12 col-md-5 double-product-wrapper-left">
                                <div className="double-product-left-bg">
                                    <div className="colored_empty_space">_</div>
                                    <div className="double-product-left hover-shadow">
                                        <div className="double-product-caption-left">
                                            <h3 className="double-product-caption-left-text">
                                                <FormattedMessage id="standard" />

                                            </h3>
                                        </div>
                                        <div className="double-product-img-left">
                                            <div className="product-img">
                                                < StandardValve />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-5 double-product-wrapper-right">
                                <div className="double-product-right-bg">
                                    <div className="colored_empty_space">_</div>
                                    <div className="double-product-right hover-shadow">
                                        <div className="double-product-caption-right">
                                            <h3 className="double-product-caption-right-text">
                                                <FormattedMessage id="fast_fill" />
                                            </h3>
                                        </div>
                                        <div className="double-product-img-right">
                                            <div className="product-img">
                                                < VenturiValve />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section title="download-brochure" className="py-4">
                        <div className="row no-gutters justify-content-md-center py-4">
                            <div className="col mx-auto">
                                <div className="d-flex justify-content-center">

                                    <a href={getPDFSource(languageCode)} download=""> <button type="button"
                                        className="btn btn-success btn-lg download-button rounded-0">
                                        <FormattedMessage id="download" />
                                    </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>


                <section title="strengths and sizes" className=" py-4">
                    <div className="row no-gutters justify-content-md-center pb-4 mx-auto" />
                    <div className="col-11 col-md-10 col-lg-8 max-width mx-auto">

                        <div className="sizes-bg">
                        </div>

                        <div className="sizes-caption">
                            <h1 className="sizes-caption-text mt-4">
                                <FormattedMessage id="chart_title_p1" />
                            </h1>
                        </div>

                        <div className="sizes-caption">
                            <h1 className="sizes-caption-text">
                                <FormattedMessage id="chart_title_p2" />
                            </h1>
                        </div>

                        <div className="sizes-container mobile-sizes-hide">
                            <div className="table table-borderless table-responsive">
                                <table className="table align-middle ">
                                    <thead>
                                        <tr className="align-middle">
                                            <th>
                                                <FormattedMessage id="material" />
                                            </th>
                                            <th><FormattedMessage id="size" /></th>
                                            <th><FormattedMessage id="strength" /></th>
                                            <th><FormattedMessage id="valve" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="align-top">
                                                <p className="table-text"><FormattedMessage id="kraft" /></p>
                                                <p className="table-text"><FormattedMessage id="paper" /></p>
                                            </td>
                                            <td>
                                                <p className="table-text">90 x 120</p>
                                                <p className="table-text">90 x 150</p>
                                                <p className="table-text">90 x 180</p>
                                                <p className="table-text">90 x 210</p>
                                            </td>
                                            <td>
                                                <p className="table-text">Light</p>
                                                <p className="table-text">Medium</p>
                                                <p className="table-text">Heavy</p>
                                                <p className="table-text">Heavy Duty</p>
                                            </td>
                                            <td className="align-top">
                                                <p className="table-text"><FormattedMessage id="standard" />
                                                </p>
                                                <p className="table-text"><FormattedMessage id="fast_fill" /></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="align-top">
                                                <p className="table-text"><FormattedMessage id="woven" /></p>
                                                <p className="table-text"><FormattedMessage id="poly" /></p>
                                            </td>
                                            <td>
                                                <p className="table-text">60 x 90</p>
                                                <p className="table-text">60 x 120</p>
                                                <p className="table-text">90 x 90</p>
                                                <p className="table-text">90 x 120</p>
                                                <p className="table-text">90 x 150</p>
                                                <p className="table-text">90 x 180</p>
                                                <p className="table-text">90 x 210</p>
                                                <p className="table-text">120 x 120</p>
                                                <p className="table-text">120 x 240</p>
                                                <p className="table-text">120 x 270</p>
                                            </td>
                                            <td className="align-top">
                                                <p className="table-text">Level 1</p>
                                                <p className="table-text">Level 2</p>
                                                <p className="table-text">Level 3</p>
                                                <p className="table-text">Level 4</p>
                                                <p className="table-text">Level 5</p>
                                            </td>
                                            <td className="align-top">
                                                <p className="table-text"><FormattedMessage id="fast_fill" /></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="sizes-container mobile-sizes-show">
                            <div className="table table-borderless table-responsive">
                                <table className="table align-middle ">
                                    <thead>
                                        <tr className="align-middle">
                                            <th>
                                                <FormattedMessage id="material" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="size" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="strength" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="valve" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="align-top">
                                                <p className="table-text">
                                                    <FormattedMessage id="kraft" />
                                                </p>
                                                <p className="table-text">
                                                    <FormattedMessage id="paper" />
                                                </p>
                                            </td>

                                            <td>
                                                <p className="table-text">90x120</p>
                                                <p className="table-text">90x150</p>
                                                <p className="table-text">90x180</p>
                                                <p className="table-text">90x210</p>
                                            </td>
                                            <td>
                                                <p className="table-text">Light</p>
                                                <p className="table-text">Medium</p>
                                                <p className="table-text">Heavy</p>
                                                <p className="table-text">Heavy Duty</p>
                                            </td>
                                            <td className="align-top">
                                                <p className="table-text">
                                                    <FormattedMessage id="standard" />
                                                </p>
                                                <p className="table-text">
                                                    <FormattedMessage id="fast_fill" />
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="align-top">
                                                <p className="table-text">
                                                    <FormattedMessage id="woven" />
                                                </p>
                                                <p className="table-text">
                                                    <FormattedMessage id="poly" />
                                                </p>
                                            </td>
                                            <td>
                                                <p className="table-text">60 x 90</p>
                                                <p className="table-text">60 x 120</p>
                                                <p className="table-text">90 x 90</p>
                                                <p className="table-text">90 x 120</p>
                                                <p className="table-text">90 x 150</p>
                                                <p className="table-text">90 x 180</p>
                                                <p className="table-text">90 x 210</p>
                                                <p className="table-text">120 x 120</p>
                                                <p className="table-text">120 x 240</p>
                                                <p className="table-text">120 x 270</p>
                                            </td>
                                            <td className="align-top">
                                                <p className="table-text">Level 1</p>
                                                <p className="table-text">Level 2</p>
                                                <p className="table-text">Level 3</p>
                                                <p className="table-text">Level 4</p>
                                                <p className="table-text">Level 5</p>
                                            </td>
                                            <td className="align-top">
                                                <p className="table-text">
                                                    <FormattedMessage id="fast_fill" />
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section title="why safetrans" className="container-fluid max-width py-4" id="about">

                    <div className="mt-4 col-12 col-md-8 mx-auto">
                        <hr />
                    </div>

                    <section title="why safetrans" className="pt-4">
                        <div className="row no-gutters justify-content-md-center pb-4 mx-auto">
                            <div className="col-12">

                                <div>
                                    <h1 className="text-center title">
                                        <FormattedMessage id="why_safetrans" />
                                    </h1>
                                </div>

                                <div className="py-4">
                                    <h5 className="text-center">
                                        <FormattedMessage id="why_safetrans_sub_p1" />

                                        <br className="mb-2" />
                                        <FormattedMessage id="why_safetrans_sub_p2" />

                                    </h5>
                                </div>

                                <div className="row py-4">
                                    <div className="col-6 col-md-4 pt-2">
                                        <div className="icon-wrapper">

                                            <Icon1 />
                                        </div>
                                        <h5 className="icon-text">
                                            <FormattedMessage id="icon_1_title" />
                                        </h5>
                                        <p className="explanation">
                                            <FormattedMessage id="icon_1_text" />
                                        </p>
                                    </div>
                                    <div className="col-6 col-md-4 pt-2">
                                        <div className="icon-wrapper">
                                            <Icon2 />
                                        </div>
                                        <h5 className="icon-text">
                                            <FormattedMessage id="icon_2_title" />
                                        </h5>
                                        <p className="explanation">
                                            <FormattedMessage id="icon_2_text" />

                                        </p>
                                    </div>
                                    <div className="col-6 col-md-4 pt-2">
                                        <div className="icon-wrapper">
                                            <Icon3 />
                                        </div>
                                        <h5 className="icon-text">
                                            <FormattedMessage id="icon_3_title" />
                                        </h5>
                                        <p className="explanation">
                                            <FormattedMessage id="icon_3_text" />
                                        </p>
                                    </div>
                                    {/* TODO: FIX */}
                                    <div className="col-6 col-md-4 pt-2">
                                        <div className="icon-wrapper">
                                            <Icon4 />
                                        </div>
                                        <h5 className="icon-text">
                                            <FormattedMessage id="icon_4_title" />
                                        </h5>
                                        <p className="explanation">
                                            <FormattedMessage id="icon_4_text" />
                                        </p>
                                    </div>
                                    <div className="col-6 col-md-4 pt-2">
                                        <div className="icon-wrapper">
                                            <Icon5 />
                                        </div>
                                        <h5 className="icon-text">
                                            <FormattedMessage id="icon_5_title" />
                                        </h5>
                                        <p className="explanation">
                                            <FormattedMessage id="icon_5_text" />
                                        </p>
                                    </div>
                                    <div className="col-6 col-md-4 pt-2">
                                        <div className="icon-wrapper">
                                            <Icon6 />
                                        </div>
                                        <h5 className="icon-text">
                                            <FormattedMessage id="icon_6_title" />
                                        </h5>
                                        <p className="explanation">
                                            <FormattedMessage id="icon_6_text" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section title="download-brochure" className="py-4">
                            <div className="row no-gutters justify-content-md-center py-4">
                                <div className="col mx-auto">
                                    <div className="d-flex justify-content-center">
                                        <a href={getPDFSourceKnowUs(languageCode)} download=""> <button type="button"
                                            className="btn btn-success btn-lg download-button rounded-0">
                                            <FormattedMessage id="know_us" />
                                        </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </section>
                </section>

                <section title="application" className="py-4" id="applications">
                    <div className="row no-gutters d-flex justify-content-center pb-4 mx-auto" />
                    <div className="col-12 max-width mx-auto">
                        <div className="app-bg">
                        </div>
                        <div className="app-caption">
                            <h1 className="app-caption-text">
                                <FormattedMessage id="applications" />
                            </h1>
                        </div>

                        <div className="pt-2 mb-4">
                            <h5 className="app-explanation">
                                <FormattedMessage id="applications_text_p1" />

                                <br className="mb-2" />
                                <FormattedMessage id="applications_text_p2" />

                            </h5>
                        </div>

                        <div className="row justify-content-md-evenly pb-4 mx-auto">

                            {/* <!--TRUCKS--> */}
                            <div className="col-12 col-md-4 pt-4 px-4">
                                <div className="card hover-shadow">

                                    <StaticImage src="../../assets/images/truck.jpeg" className="card-img-top zoom-img" alt="..." />
                                    <h4 className="card-title">
                                        <FormattedMessage id="container" />
                                    </h4>

                                    <div className="card-body">

                                        {/* <!--LIQUOR--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="container_1" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--CERAMICS--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="container_2" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--CHEMICALS--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="container_3" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--ETC--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="container_4" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--SHIP--> */}
                            <div className="col-12 col-md-4 pt-4 px-4">
                                <div className="card hover-shadow">
                                    <StaticImage src="../../assets/images/ship.jpg" className="card-img-top zoom-img" alt="..." />
                                    <h4 className="card-title">
                                        <FormattedMessage id="ship" />
                                    </h4>

                                    <div className="card-body">

                                        {/* <!--FRUIT--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="ship_1" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--TIMBER--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="ship_2" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--PAPER--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="ship_3" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--ETC--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="ship_4" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--TRAIN--> */}
                            <div className="col-12 col-md-4 pt-4 px-4">
                                <div className="card hover-shadow">

                                    <StaticImage src="../../assets/images/train.jpg" className="card-img-top zoom-img" alt="..." />

                                    <h4 className="card-title">
                                        <FormattedMessage id="train" />
                                    </h4>

                                    <div className="card-body">

                                        {/* <!--FRUIT--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="train_1" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--TIMBER--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="train_2" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--PAPER--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="train_3" />
                                                </p>
                                            </div>
                                        </div>

                                        {/* <!--ETC--> */}
                                        <div className="row summation-row my-1">
                                            <div className="col-auto">
                                                <StaticImage src="../../assets/icons/Checkmark icon 2.png" alt="" width={30} height={30}
                                                    className="d-inline-block summation-row-icon" />
                                            </div>
                                            <div className="col summation-row-text">
                                                <p className="card-text">
                                                    <FormattedMessage id="train_4" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section title="contact" className="pt-4" id="contact">
                    <div className="row no-gutters justify-content-md-center mx-auto">
                        <div className="col-12 footer-bg pb-5">
                            <div className="footer-caption mb-4">
                                <h1 className="footer-caption-text">
                                    <FormattedMessage id="contact_us" />
                                </h1>
                            </div>
                            <h6 className="footer-explanation">
                                <FormattedMessage id="contact_us_p1" />

                                <br className="mb-1" />
                                <FormattedMessage id="contact_us_p2" />

                            </h6>
                            <h6 className="pt-4 footer-explanation pb-4">
                                <div className="mobile-phone">+31 (0)413 257953</div> | <div className="ms-4">sales@safetrans.nl</div>
                            </h6>
                            <h6 className="pt-4 footer-explanation">Kuiperstraat 4, 5405 BB, Uden<br />
                                <FormattedMessage id="netherlands" />

                            </h6>
                        </div>
                    </div>
                </section>
            </body>
        </>
    );
}

export default IndexContent;
